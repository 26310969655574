// Given a time in milliseconds (from the start of a run), return a string in the format of "mm:ss".
export const formatTime = (timeInMs) => {
  const seconds = Math.floor(timeInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const formatActionLabel = (action) => {
  const actionMap = {
    click_element: 'Click',
    scroll_element: 'Scroll',
    complete_form: 'Fill out form',
    satisfied_objective: 'Objective met',
    raise_issue: 'Hand raise',
    reload_page: 'Reload page',
    user_session_summary: 'Run summary',
  };
  return `${actionMap[action] || action}`;
};
