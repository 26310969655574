import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { formatTime, formatActionLabel } from '../lib/utils';

import IconPlay from '../../assets/images/player-control-play.svg';
import IconPause from '../../assets/images/player-control-pause.svg';

// Need to pass in onClick mostly so a click on a point will tell the parent to move the timeline there.
// Can't have clicks on them not do anything, but also can't just disable pointer-events because we need hover.
const ActionPoint = ({ debug, position, label, thumbnail, time, onClick }) => {
  return (
    <div
      className="group absolute top-1/2 z-10 hidden -translate-y-1/2 cursor-pointer md:block"
      style={{ left: `${Math.min(Math.max(position, 0), 100)}%` }}
      onClick={onClick}
    >
      <div className="h-[10px] w-[9px] border-2 border-white bg-gray-800" title={label} />

      <div
        className={`pointer-events-none absolute bottom-6 left-1/2 z-10 min-w-32 -translate-x-1/2 translate-y-[-10px] scale-95 overflow-hidden rounded border border-gray-300 bg-white opacity-0 transition-all group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100`}
      >
        <p className="flex items-center justify-between gap-1 px-2 py-1 text-xs">
          <span className="font-medium">{label}</span>
          <span className="text-gray-500">{formatTime(time < 0 ? 0 : time)}</span>
        </p>
        {thumbnail && (
          <div className="w-full bg-gray-300">
            <img
              className="h-auto"
              style={{ aspectRatio: `${thumbnail.info.width}/${thumbnail.info.height}` }}
              src={thumbnail.base64}
              alt={label}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Controls = ({ debug, video, actions }) => {
  const [state, setState] = useState('playing');
  const [time, setTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const progressBarRef = useRef(null);

  useHotkeys('space', (e) => {
    e.preventDefault();
    video.toggle();
  });

  const processedActions = useMemo(() => {
    if (!actions || totalTime === 0 || startTime === 0) return [];
    return actions.map((action) => ({ ...action, position: ((action.timestamp - startTime) / totalTime) * 100 }));
  }, [actions, startTime, totalTime]);

  useEffect(() => {
    const meta = video.getMetaData();
    setTotalTime(meta.totalTime);
    setStartTime(meta.startTime);

    const _setTime = (e) => setTime(e.payload);
    const _setState = (e) => setState(e.payload);
    const _setProgress = (e) => setProgress(e.payload);

    video.addEventListener('ui-update-current-time', _setTime);
    video.addEventListener('ui-update-player-state', _setState);
    video.addEventListener('ui-update-progress', _setProgress);

    return () => {
      // TODO: rrwebPlayer doesn't have a removeEventListener...
      // video.removeEventListener('ui-update-current-time', _setTime);
      // video.removeEventListener('ui-update-player-state', _setState);
      // video.removeEventListener('ui-update-progress', _setProgress);
    };
  }, [video]);

  // Note that clicks on ActionPoints are passed through here too.
  const handleProgressBarClick = (e) => {
    if (!progressBarRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const clickPosition = (e.clientX - rect.left) / rect.width;
    const newTime = clickPosition * totalTime;
    video.goto(newTime);
  };

  return (
    <div className="mx-auto w-full">
      <div className="my-5 flex items-center justify-between gap-x-3">
        <button
          onClick={() => video.toggle()}
          className="flex size-9 shrink-0 flex-col items-center justify-center rounded-full bg-gray-800 text-white hover:bg-gray-600"
        >
          {state === 'playing' ? <IconPause /> : <IconPlay />}
        </button>
        <div className="relative flex-grow">
          <div ref={progressBarRef} className="relative h-1 w-full rounded-full bg-gray-200">
            <div className="h-full rounded bg-gray-800" style={{ width: `${progress * 100}%` }} />
            {processedActions.map((action, index) => (
              <ActionPoint
                onClick={(e) => handleProgressBarClick(e)}
                key={index}
                debug={debug}
                label={formatActionLabel(action.interactionType)}
                time={action.timestamp - startTime}
                position={action.position}
                thumbnail={action.thumbnail}
              />
            ))}
          </div>
          <div className="absolute left-0 top-1/2 h-8 w-full -translate-y-1/2 cursor-pointer" onClick={handleProgressBarClick} />
        </div>
        <span className="shrink-0 rounded-full bg-gray-200 px-6 py-3 font-mono text-xs font-semibold">
          {formatTime(time > totalTime ? totalTime : time)}
          <span className="text-gray-500"> / {formatTime(totalTime)}</span>
        </span>
      </div>
    </div>
  );
};

export default Controls;
